.flatpickr-calendar:not(.inline) .numInputWrapper,
.flatpickr-calendar:not(.inline) .flatpickr-monthDropdown-months {
  top: 10px;
}
.flatpickr-calendar.open {
	z-index: 99999999999999999999999999999999999 !important;
}
.flatpickr-day.selected, .flatpickr-day.startRange, .flatpickr-day.endRange, .flatpickr-day.selected.inRange, .flatpickr-day.startRange.inRange, .flatpickr-day.endRange.inRange, .flatpickr-day.selected:focus, .flatpickr-day.startRange:focus, .flatpickr-day.endRange:focus, .flatpickr-day.selected:hover, .flatpickr-day.startRange:hover, .flatpickr-day.endRange:hover, .flatpickr-day.selected.prevMonthDay, .flatpickr-day.startRange.prevMonthDay, .flatpickr-day.endRange.prevMonthDay, .flatpickr-day.selected.nextMonthDay, .flatpickr-day.startRange.nextMonthDay, .flatpickr-day.endRange.nextMonthDay {
    background: var(--flatpickr-color) !important;
    border-color: var(--flatpickr-color) !important;        
}
.flatpickr-months .flatpickr-prev-month:hover svg, 
.flatpickr-months .flatpickr-next-month:hover svg {
    fill: inherit !important;    
}
.flatpickr-current-month {
    padding: 0px !important;
}
.flatpickr-day.nextMonthDay {
    color: rgba(57,57,57,0.5);
}
.flatpickr-calendar .flatpickr-monthDropdown-months,
.flatpickr-calendar .numInputWrapper {
	top: 5px;
}
.flatpickr-calendar.arrowBottom:after {
    border-top-color: #ffffff;
}
.flatpickr-calendar.arrowTop:after {
  border-top-color: #ffffff;
}
.flatpickr-months .flatpickr-month {
  border-radius: 5px 5px 0 0;
  background: var(--flatpickr-header-background);
  color: var(--flatpickr-header-color);
  fill: var(--flatpickr-header-color);
  height: 34px;
  line-height: 1;
  text-align: center;
  position: relative;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  overflow: hidden;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
.flatpickr-current-month .flatpickr-monthDropdown-months {
  appearance: menulist;
  background: var(--flatpickr-header-background);
  border: none;
  border-radius: 0;
  box-sizing: border-box;
  color: inherit;
  cursor: pointer;
  font-size: inherit;
  font-family: inherit;
  font-weight: 300;
  height: auto;
  line-height: inherit;
  margin: -1px 0 0 0;
  outline: none;
  padding: 0 0 0 0.5ch;
  position: relative;
  vertical-align: initial;
  -webkit-box-sizing: border-box;
  -webkit-appearance: menulist;
  -moz-appearance: menulist;
  width: auto;
}
.flatpickr-current-month .flatpickr-monthDropdown-months .flatpickr-monthDropdown-month {
  background-color: var(--flatpickr-header-background);
  outline: none;
  padding: 0;
}
.flatpickr-weekdays {
  background: var(--flatpickr-header-background);
  text-align: center;
  overflow: hidden;
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  height: 28px;
}
span.flatpickr-weekday {
  cursor: default;
  font-size: 90%;
  background: var(--flatpickr-header-background);
  color: rgba(0,0,0,0.54);
  line-height: 1;
  margin: 0;
  text-align: center;
  display: block;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
      -ms-flex: 1;
          flex: 1;
  font-weight: bolder;
}
.flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n+1)),
.flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n+1)),
.flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n+1)) {
  -webkit-box-shadow: -10px 0 0 var(--flatpickr-header-background);
          box-shadow: -10px 0 0 var(--flatpickr-header-background);
}
.flatpickr-day.week.selected {
  border-radius: 0;
  -webkit-box-shadow: -5px 0 0 var(--flatpickr-header-background), 5px 0 0 var(--flatpickr-header-background);
          box-shadow: -5px 0 0 var(--flatpickr-header-background), 5px 0 0 var(--flatpickr-header-background);
}
.flatpickr-months .flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month {
  text-decoration: none;
  cursor: pointer;
  position: absolute;
  top: 0;
  height: 34px;
  padding: 10px;
  z-index: 3;
  color: var(--flatpickr-header-color);
  fill: var(--flatpickr-header-color);
}